<template>
  <div>
    <page-title :heading="'Webhooks'" :subheading="$t('generic.lang_edit')" :icon=icon :show-previous-button="true" ></page-title>
    <div class="app-main__inner">
      <EditWebhooksComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import EditWebhooksComponent from "../../../../components/settings/moduleSettings/webhooks/EditWebhooksComponent";
export default {
  components: {
    EditWebhooksComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>